import * as React from 'react';
import { useState } from 'react';
import { connect } from 'react-redux';
import Moment from 'moment';
import {jsPDF} from 'jspdf';
import autoTable from 'jspdf-autotable';

import WD from './../../assets/wdNew.png';

import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Tooltip from '@mui/material/Tooltip';
import TablePagination from '@mui/material/TablePagination';
import Button from '@mui/material/Button';
import SearchIcon from '@mui/icons-material/Search';
import {Row, Col} from 'react-bootstrap';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';
import {ReactComponent as PdfIcon} from '../../assets/pdf-svgrepo-com.svg';

import Modal from 'react-bootstrap/Modal';


import FormInput from '../form-input/form-input.component.jsx';
import AdvancedSearch from '../advanced-search/advanced-search.component';
import ExportUserPurchaseExcel from '../user-purchase-export-to-excel/user-purchase-export-to-excel.component.jsx';

import { convertDateAndTimeInEST } from './../../factory.js';

import { updateUserPurchaseDeliveryStatusStart } from '../../redux/user-purchase/user-purchase.action';

import './admin-user-purchase-list.style.scss';

const RowsOfTable = (props) => {
  const { row, handleDeliveryStatus } = props;

  const [open, setOpen] = React.useState(false);

  const [modalShow, setModalShow] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} style={row.status === 'Delivered' ? {
        background: 'linear-gradient(90deg, rgba(110,249,66,0.47942927170868344) 100%, rgba(2,0,36,1) 100%, rgba(0,212,255,1) 100%)'}: null}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.name}
        </TableCell>
        <TableCell >{row.email}</TableCell>
        <TableCell >{row.order_id}</TableCell>
        <TableCell >{convertDateAndTimeInEST(row.createdAt)}</TableCell>
        <TableCell align="right">{row.gross_amount}</TableCell>
        <TableCell align="right">{row.total_amount}</TableCell>
        <TableCell align="right">{row.discount}</TableCell>
        <TableCell >{row.merchant}</TableCell>
        <TableCell style={row.status === 'Delivered' ? {color: 'blue'}: {color: 'red'}}>{row.status}</TableCell>
        <TableCell >
          <input type="button" value='Generate' className='generate-receipt' onClick={() => setModalShow(true)}/>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Purchased Product
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell style={{fontWeight: '600'}}>S_No</TableCell>
                    <TableCell style={{fontWeight: '600'}}>Date</TableCell>
                    <TableCell style={{fontWeight: '600'}}>Product</TableCell>
                    <TableCell style={{fontWeight: '600'}}>Quantity</TableCell>
                    <TableCell style={{fontWeight: '600'}}>Unit Amount($)</TableCell>
                    <TableCell style={{fontWeight: '600'}}>Total Amount($)</TableCell>
                    <TableCell style={{fontWeight: '600'}}>Delivery Status</TableCell>
                    {
                      row.status !== 'Delivered' ? 
                      <TableCell style={{fontWeight: '600'}}>Action</TableCell>
                      : null
                    }
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    typeof row !== "undefined" ? 
                    row.items.map((itemRow, ind) => (
                    <TableRow key={'itemRow_' + ind}>
                        <TableCell>{ind + 1}</TableCell>
                        <TableCell component="th" scope="row">
                            {convertDateAndTimeInEST(itemRow.createdAt)}
                        </TableCell>
                        <TableCell>{itemRow.description}</TableCell>
                        <TableCell align="right">{itemRow.quantity}</TableCell>
                        <TableCell align="right">{itemRow.unit_amount}</TableCell>
                        <TableCell align="right">
                            {(itemRow.quantity * itemRow.unit_amount).toFixed(2)}
                        </TableCell>
                        <TableCell align='right' style={!!itemRow.deliveryStatus ? {color: 'green'} : {color: 'red'}}>
                        {
                            !!itemRow.deliveryStatus ? 'Delivered' : 'Un-Delivered'
                        }
                        </TableCell>
                        {
                          !itemRow.deliveryStatus ? 
                          <TableCell>
                            <Tooltip title="Update Delivery Status">
                              <i 
                                className="fa fa-truck delivery-status-icon" 
                                aria-hidden="true" 
                                style={{fontSize: '24px'}} 
                                onClick = {() => handleDeliveryStatus(itemRow._id)}
                              ></i>
                            </Tooltip>
                          </TableCell>
                          : null
                        }
                    </TableRow>
                  )) : ''
                }
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        data = {row}
      />
    </React.Fragment>
  );
}

const MyVerticallyCenteredModal = (props) => {

  var date = new Date(props.data.createdAt).toISOString().slice(0, 10);;
  date = date.split('-');
  const newDate = [date[2], date[1], date[0]].join('-');

  const [value, setValue] = useState('online');
  const [shippedTo, setShippedTo] = useState('');
  const [error, setError] = useState(false);

  const handleSelectChange = (event) => {
    const valueNew = event.target.value;
    setValue(() => valueNew);
    setShippedTo(() => '');
    setError(() => false);
  }

  const handleChange = (e) => {
    const value = e.target.value;
    setShippedTo(() => value);
    setError(() => false);
  }

  const generateReceiptPDF = () => {
    console.log(shippedTo);
    if(shippedTo !== ''){
      console.log(props.data);
      handleFormDownload(props.data);
    }
    else{
      setError(() => true);
    }
  }

  const handleFormDownload = (event) => {

    /********************************************************/
    /********************************************************/
    /****************CODE FOR PDF GENERATION*****************/
    /********************************************************/
    /********************************************************/

    const doc = new jsPDF('portrait', 'px', 'a4', 'false');
    doc.addImage(WD, 'PNG', 30, 5, 40, 40);
    
    // Setting Header of PDF
    doc.setFont("helvetica");
    doc.setFontSize(20);
    doc.text('RECEIPT', 225, 30, 'center');
    // doc.setFontSize(14);
    // doc.setFont(undefined, 'bold');
    // doc.text('Webinarskey', 30, 50);
    doc.setFontSize(11);
    doc.setFont(undefined, 'normal');
    doc.text('Website: webinarskey.com', 35, 50);
    doc.text('Contact: cs@webinarskey.com', 35, 60);

    doc.setFontSize(12);
    doc.setFont(undefined, 'bold');
    doc.text('RECEIPT #', 275, 50);
    doc.text('PURCHASE DATE', 275, 60);

    doc.setFontSize(11);
    doc.setFont(undefined, 'normal');
    doc.text('WD-245', 375, 50);
    doc.text('23-10-2022', 375, 60);


    // Setting Tables in PDF
    var startingPage = doc.internal.getCurrentPageInfo().pageNumber;
    // autoTable(doc, {
    //     head: [['Webinarskey']],
    //     body: [
    //         ["Website: webinarskey.com"],
    //         ["Contact: cs@webinarskey.com"]
    //     ],
    //     margin:{
    //         top:50,
    //         right: 230
    //     },
    //     headStyles:{
    //         valign: 'middle',
    //         halign : 'left',
    //         font: 'times',
    //         fontSize: 14,
    //         fontStyle: 'bold'
    //     },
    //     theme: 'plain',
    //     avoidPageSplit: true
    // });

    autoTable(doc, {
      head: [['BILL TO']],
      body: [
          ["John Doe"],
          ["41, CC-Venue, NY, USA"]
      ],
      margin:{
          top:80,
          right: 230
      },
      headStyles:{
          valign: 'middle',
          halign : 'left',
          font: 'times',
          fontSize: 14,
          fontStyle: 'bold'
      },
      bodyStyles: {
        font: 'helvetica',
        fontSize: 12,
      },
      theme: 'plain',
      avoidPageSplit: true
    });

    autoTable(doc, {
      head: [['SHIP TO']],
      body: [
          ["John Doe"],
          ["41, CC-Venue, NY, USA"]
      ],
      margin:{
          top:80,
          right: 230
      },
      headStyles:{
          valign: 'middle',
          halign : 'left',
          font: 'times',
          fontSize: 14,
          fontStyle: 'bold'
      },
      bodyStyles: {
        font: 'helvetica',
        fontSize: 12,
      },
      theme: 'plain',
      avoidPageSplit: true
    });

    doc.setPage(startingPage);

    // Setting Second Heading in PDF.
    doc.setFont("helvetica");
    doc.setFontSize(16);
    doc.text('ORDER DETAILS', 225, 225, 'center');
    doc.setFontSize(12);

    // Setting Conference Name and Date in PDF.
    // doc.text('Conference Title: ', 30, 350, 'left').setFont(undefined, 'normal');
    // doc.text(name, 120, 350).setFont(undefined, 'bold');

    // doc.text('Conference Date: ', 30, 360, 'left').setFont(undefined, 'normal');
    // doc.text(date.convertToDate().split(' ').splice(1).join(' '), 120, 360).setFont(undefined, 'bold');

    // Setting Dynamic Table in PDF for Quantity.
    var productArray = [];
    // if(typeof cost !== "undefined"){
    //     cost.map((prdArray) => {
    //         if(prdArray.price !== '' && prdArray.price !== null){
    //             var filterArray = [];
    //             filterArray.push(prdArray.name);
    //             filterArray.push('________');
    //             filterArray.push("$" + prdArray.price);
    //             filterArray.push('________');
    //             productArray.push(filterArray);
    //         }
    //     });

    //     productArray.push(['', '', 'Coupon Code', '________']);
    //     productArray.push(['', '', 'Total', '________']);
    // }
    autoTable(doc, {
      head: [['#','Product', 'Quantity', 'Price', 'Total']],
      body: [
        ['1', 'Joint Commission 2022 National Patient Safety Goals (NPSG 2022) for Acute and Critical Acc... (Live webinar)', '1', '$249', '$249'],
        ['2', 'Joint Commission 2022 National Patient Safety Goals (NPSG 2022) for Acute and Critical Acc... (Live webinar)', '1', '$249', '$249']
      ],
      // body: productArray,
      startY: 250,
      theme: 'plain'
    });

    // Setting Auto tables.
    autoTable(doc, {
        body:[
            // ['Conference Title:', name],
            // ['Conference Date:', date.convertToDate().split(' ').splice(1).join(' ')]
        ],
        theme: 'plain',
        startY: 330,
        styles: { 
            fontSize: 11 
        },
        columnStyles: {
            0: {
                cellWidth: 80,
                fontStyle: 'bold'
            },
            1: {
                halign: 'left'
            }
          }
    });

    doc.text('Please send the completed order form via fax or e-mail',220, 620, 'center');
    doc.save('order_form.pdf');
    doc.autoPrint();
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Generate Receipt
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col align = "right" style = {{color: 'green', fontSize: '20px'}}>${props.data.total_amount}</Col>
        </Row>
        <Row>
          <Col md = {3}>
            <strong>
              Order ID
            </strong>
          </Col>
          <Col md = {1}>
            :
          </Col>
          <Col>{props.data.order_id}</Col>
        </Row>
        <Row>
          <Col md = {3}>
            <strong>
              Customer Name
            </strong>
          </Col>
          <Col md = {1}>
            :
          </Col>
          <Col><strong>{props.data.name}</strong></Col>
        </Row>
        <Row>
          <Col md = {3}>
            <strong>
              Email
            </strong>
          </Col>
          <Col md = {1}>
            :
          </Col>
          <Col>{props.data.email}</Col>
        </Row>
        <Row>
          <Col md = {3}>
            <strong>
              Date
            </strong>
          </Col>
          <Col md = {1}>
            :
          </Col>
          <Col>{newDate}</Col>
        </Row>
      </Modal.Body>
      <Modal.Body className = 'receipt-modal-body'>
        <Row className = "receipt-modal-row">
          <Col md = {6}><strong>Order details</strong></Col>
          <Col md = {2} align = "right"><strong>Quantity</strong></Col>
          <Col md = {2} align = "right"><strong>Price</strong></Col>
          <Col md = {2} align = "right"><strong>Subtotal</strong></Col>
        </Row>
        {
          props.data.items.map((item) => (
                <Row key = {props.data.order_id}>
                  <Col md = {6}>{item.description}</Col>
                  <Col md = {2} align = "right">{item.quantity}</Col>
                  <Col md = {2} align = "right">${item.unit_amount}</Col>
                  <Col md = {2} align = "right">${(item.quantity * item.unit_amount).toFixed(2)}</Col>
                </Row>
            ))
        }
        <Row className = "purchase-total-row">
          <Col md = {10} align = "right">Purchase total</Col>
          <Col md = {2} align = "right">${props.data.gross_amount}</Col>
        </Row>

        <Row className = "receipt-modal-row">
          <Col><strong>Payment details</strong></Col>
        </Row>
        <Row>
          <Col md = {3}>Purchase total</Col>
          <Col md = {2} align = "right">${props.data.gross_amount}</Col>
        </Row>
        <Row>
          <Col md = {3}>Shipping amount</Col>
          <Col md = {2} align = "right">$0.00</Col>
        </Row>
        <Row>
          <Col md = {3}>Handling amount</Col>
          <Col md = {2} align = "right">$0.00</Col>
        </Row>
        <Row>
          <Col md = {3}>Insurance amount</Col>
          <Col md = {2} align = "right">$0.00</Col>
        </Row>
        <Row>
          <Col md = {3}>Discount</Col>
          <Col md = {2} align = "right">-${props.data.discount}</Col>
          <Col md = {2}>(TransactionDiscount)</Col>
        </Row>
        <Row md = {5}>
          <Col className = "purchase-total-row" md = {3}>Net amount</Col>
          <Col className = "purchase-total-row" md = {2} align = "right"><strong>${props.data.total_amount}</strong></Col>
        </Row>

        <Row className = "receipt-modal-row">
          <Col><strong>Shipping details</strong></Col>
        </Row>
        <Row className = "mode">
          <Col md = {3} className = "mode-label">
            <strong>
            Mode
            </strong>
          </Col>
          <Col md = {1} className = "mode-label">
            :
          </Col>
          <Col md = {2} align = "right">
            <select className='select-mode' value = {value} onChange={handleSelectChange} style = {{width: '100%'}}>
              <option value='online'>Online</option>
              <option value='offline'>Offline</option>
            </select>
          </Col>
        </Row>
        {
          value === 'online' ? 
            <Row className = "mode">
              <Col md = {3} className = "mode-label">
                <strong>
                Email for delivery*
                </strong>
              </Col>
              <Col md = {1} className = "mode-label">
                :
              </Col>
              <Col md = {4} align = "right">
                <input type= 'text' className = 'select-mode' value={shippedTo} onChange = {handleChange} style={{width: '100%'}}/>
              </Col>
              {

                error && <Col md = {4} style = {{color: 'red'}} className = "mode-label">
                  *Field is Mandatory.
                </Col>
              }
            </Row>
            :
            <Row className = "mode">
              <Col md = {3} className = "mode-label">
                <strong>
                Address for delivery*
                </strong>
              </Col>
              <Col md = {1} className = "mode-label">
                :
              </Col>
              <Col md = {4} align = "right">
                <textarea className = 'select-mode' value={shippedTo} onChange = {handleChange} rows="4"/>
              </Col>
              {
                error && <Col md = {4} style = {{color: 'red'}} className = "mode-label">
                  *Field is Mandatory.
                </Col>
              }
            </Row>
        }

      </Modal.Body>
      <Modal.Footer>
        <Tooltip title="Generate PDF Receipt" placement='left-start'>
          <PdfIcon height={40} width = {50} className = "pdf-icon" onClick={generateReceiptPDF}/>
        </Tooltip>
        {/*<Button onClick={props.onHide}>Close</Button>*/}
      </Modal.Footer>
    </Modal>
  );
}

const AdminUserPurchaseList = ({data, updateUserPurchaseDeliveryStatusStart}) => {
    const createData = (name, email, gross_amount, merchant, order_id, total_amount, items, discount, createdAt, status, itemLength) => {
        return {
          name,
          email,
          order_id,
          gross_amount,
          total_amount,
          discount,
          merchant,
          createdAt,
          status,
          items,
          itemLength
        };
      }
    const [rows, setRows] = React.useState([]);
    
    const handleDeliveryStatus = (productId) => {
      updateUserPurchaseDeliveryStatusStart({id: productId, status: true});
    }

    const Root = styled('div')(({ theme }) => ({
      width: '100%',
      ...theme.typography.body2,
      '& > :not(style) + :not(style)': {
          marginTop: theme.spacing(8),
      },
  }));

    const [newData, setNewData] = React.useState();
    React.useEffect(() => {
      if(data !== ''){
          data = data.sort((a,b) => new Moment(a.createdAt).format('X') - new Moment(b.createdAt).format('X')).reverse();
          var newArr = [];
            data.map((userData) => {
                const discount = (userData.gross_amount - userData.total_amount).toFixed(2);
                var status = 'Delivered';
                userData.items.map((item) => {
                    if(item.deliveryStatus === false){
                        status = 'Un-Delivered';
                    }
                });
                    newArr.push(
                        createData(
                            userData.name, 
                            userData.email, 
                            userData.gross_amount, 
                            userData.merchant, 
                            userData.order_id, 
                            userData.total_amount, 
                            userData.items, 
                            discount,
                            userData.createdAt,
                            status,
                            userData.items.length
                        )
                    )
            });
            setRows(newArr);
            setNewData(newArr);
        }
    }, [data]);

    // For Pagination
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    // To change Pages
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    // To change Rows per page
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const [search, setSearch] = React.useState('');
    const handleSearch = (event) => {
        const {value} = event.target;
        setSearch(value);
        if(value !== ""){
            setRows(newData.filter((data) => {
                return Object.values(data)
                    .join(" ")
                    .toLowerCase()
                    .includes(value.toLowerCase());
            })); 
        }
        else {
            setRows(newData);
        }
    }
    
    const [openSearch, setOpenSearch] = React.useState(false);
    const handleAdvancedSearch = () => {
      setOpenSearch(true);
    }

    const closeAdvancedSearch = () => {
      setOpenSearch(false);
    }

    // Excel Sheet Export 
  

    return (
      <>
        <Row className = "m-t-30">
          <Col>
            <h2>User Purchase List</h2>
          </Col>
        </Row>
        <Row>
              <Col md = {10} xs= {10} xm = {10} className="search-input" style={{display: 'flex'}}>
                <Tooltip title="Search">
                  <Button 
                    variant="outlined" 
                    color="success" 
                    startIcon={<SearchIcon />} 
                    onClick = {handleAdvancedSearch}
                    style={{marginLeft: 'auto'}}
                  >
                    Search
                  </Button>
                </Tooltip>
                  {
                    openSearch && <AdvancedSearch openDialog = {openSearch} closeAdvancedSearch = {closeAdvancedSearch}/>
                  }
              </Col>
              <Col md = {2} xs= {2} xm = {2} style={{display: 'flex'}}>
                <Divider orientation="vertical" flexItem style={{height: '45px', marginLeft: 'auto'}}/>
                  <ExportUserPurchaseExcel data = {rows}/>
              </Col>
        </Row>
        <Row>
          <TableContainer component={Paper} className="user-Purchase-list">
              <Table aria-label="collapsible table" id="user-purchase">
                  <TableHead>
                      <TableRow>
                          <TableCell />
                          <TableCell style={{fontWeight: '600'}}>Payer</TableCell>
                          <TableCell style={{fontWeight: '600'}}>Email</TableCell>
                          <TableCell style={{fontWeight: '600'}}>Order ID</TableCell>
                          <TableCell style={{fontWeight: '600'}}>Purchase Date</TableCell>
                          <TableCell style={{fontWeight: '600'}}>Total Amount($)</TableCell>
                          <TableCell style={{fontWeight: '600'}}>Amount Payable($)</TableCell>
                          <TableCell style={{fontWeight: '600'}}>Discount($)</TableCell>
                          <TableCell style={{fontWeight: '600'}}>Merchant</TableCell>
                          <TableCell style={{fontWeight: '600'}}>Status</TableCell>
                          <TableCell style={{fontWeight: '600'}}>Receipt</TableCell>
                      </TableRow>
                  </TableHead>
                  <TableBody>
                      {

                          rows.length > 0 ?
                          rows
                          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .map((row, index) => (
                              <RowsOfTable key={row.order_id+'_'+index} row={row} handleDeliveryStatus = {handleDeliveryStatus}/>
                          )) 
                          : 
                          <TableRow>
                            <TableCell colSpan={10} align='center'>No Record(s) Found.</TableCell>
                          </TableRow>
                      } 
                  </TableBody>
              </Table>
              <TablePagination
                  rowsPerPageOptions={[ 5, 10, 25, 100]}
                  component="div"
                  count={rows !== null ? rows.length : 0}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  />
          </TableContainer>
        </Row>
      </>
    );
}

const mapDispatchToProps = dispatch => ({
  updateUserPurchaseDeliveryStatusStart: (data) => dispatch(updateUserPurchaseDeliveryStatusStart(data))
})
export default connect(null, mapDispatchToProps)(AdminUserPurchaseList);