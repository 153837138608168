import UserActionTypes from "./user.type";

const INITIAL_STATE = {
    currentUser: null,
    error: null,
    loading: false,
    signUpLoading: false,
};

const userReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case UserActionTypes.EMAIL_SIGN_IN_START:
            return {
                loading: true,
            };
        case UserActionTypes.SIGN_IN_SUCCESS:
            return {
                ...state,
                currentUser: action.payload,
                error: null,
                loading: false,
            };

        case UserActionTypes.SIGN_OUT_SUCCESS:
            return {
                ...state,
                currentUser: null,
                error: null,
            };
        case UserActionTypes.SIGN_UP_START:
            return {
                signUpLoading: true,
            };
        case UserActionTypes.SIGN_UP_SUCCESS:
            return {
                signUpLoading: false,
            };
        case UserActionTypes.SIGN_IN_FAILURE:
        case UserActionTypes.SIGN_OUT_FAILURE:
        case UserActionTypes.SIGN_UP_FAILURE:
            return {
                ...state,
                error: action.payload,
                loading: false,
                signUpLoading: false,
            };
        default:
            return state;
    }
};

export default userReducer;
