import React from "react";

const SvgWD = (prop) => (
    <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="497.000000pt"
        height="393.000000pt"
        viewBox="0 0 497.000000 393.000000"
        preserveAspectRatio="xMidYMid meet"
        {...prop}
    >
        <g
            transform="translate(0.000000,393.000000) scale(0.100000,-0.100000)"
            fill="rgb(242, 140, 40)"
            stroke="none"
        >
            <path
                d="M2380 3759 c-60 -18 -60 -17 -60 -190 0 -99 -4 -159 -10 -159 -6 0
-10 58 -10 155 0 182 -1 183 -95 166 -64 -12 -90 -24 -127 -59 -28 -25 -28
-28 -28 -144 0 -72 -4 -118 -10 -118 -6 0 -10 41 -10 104 0 61 -4 107 -10 111
-13 8 -91 -37 -139 -81 -21 -18 -50 -57 -66 -86 -15 -29 -41 -68 -56 -85 -36
-41 -51 -94 -47 -158 l3 -50 685 -5 c377 -3 686 -6 688 -8 2 -1 10 -18 18 -37
17 -42 9 -86 -26 -135 -37 -52 -50 -40 -50 49 0 102 -4 106 -125 106 -121 0
-125 -4 -125 -107 0 -59 -3 -79 -12 -76 -9 3 -14 32 -16 86 -3 79 -4 82 -30
89 -44 13 -189 8 -207 -7 -11 -9 -15 -32 -15 -91 0 -46 -4 -79 -10 -79 -6 0
-10 33 -10 79 0 60 -4 82 -16 92 -18 15 -160 19 -206 6 -26 -7 -27 -10 -30
-89 -2 -54 -7 -83 -15 -86 -10 -3 -13 17 -13 77 0 78 -1 82 -26 91 -28 11
-365 12 -404 1 -50 -14 -77 -128 -49 -210 15 -44 35 -51 139 -51 69 0 91 -3
88 -12 -3 -9 -34 -14 -96 -16 -79 -2 -92 -5 -92 -20 0 -35 60 -143 104 -186
l44 -45 133 -3 c78 -2 137 1 145 7 7 6 14 28 16 50 l3 40 83 3 82 3 0 -39 c0
-24 8 -49 20 -65 20 -25 27 -26 118 -29 78 -2 97 -6 97 -18 0 -13 -31 -16
-200 -20 -110 -3 -208 -9 -217 -13 -58 -29 -69 -138 -19 -188 l29 -29 201 0
c136 0 202 -4 207 -11 14 -24 -28 -29 -239 -29 -217 0 -218 0 -226 -22 -9 -25
-4 -49 13 -60 6 -4 115 -8 242 -8 220 0 231 1 253 21 43 40 51 90 25 145 -28
59 -50 64 -264 64 -162 0 -190 2 -190 15 0 13 28 15 190 15 151 0 197 3 220
15 58 30 77 112 40 173 -25 41 -54 52 -136 52 -71 0 -90 16 -60 49 21 23 20
64 -2 84 -16 14 -44 17 -188 17 -119 0 -174 -4 -182 -12 -7 -7 -12 -29 -12
-50 0 -44 -15 -51 -96 -46 -66 4 -62 22 6 28 l55 5 3 119 c2 89 -1 121 -10
128 -7 4 -50 8 -95 8 -49 0 -83 4 -83 10 0 15 193 13 208 -2 7 -7 12 -40 12
-80 0 -56 3 -69 19 -78 25 -13 171 -13 205 0 25 9 26 13 26 90 0 47 4 80 10
80 6 0 10 -31 10 -74 0 -102 5 -106 130 -106 125 0 130 4 130 106 0 43 4 74
10 74 6 0 10 -33 10 -80 0 -97 4 -100 125 -100 121 0 125 3 125 100 0 64 3 80
15 80 11 0 15 -19 17 -92 l3 -93 42 -3 c56 -4 148 37 196 86 97 100 105 269
17 332 -27 19 -46 20 -669 20 -575 0 -642 2 -648 16 -6 16 24 65 45 72 7 2 12
-6 12 -21 0 -17 7 -27 23 -31 35 -9 163 -7 191 4 25 9 25 11 28 136 2 91 7
129 16 132 9 3 12 -27 12 -127 0 -155 -3 -151 124 -151 125 0 126 1 126 185 0
90 4 145 10 145 6 0 10 -58 10 -155 0 -185 -4 -179 130 -173 121 4 120 3 120
154 0 76 4 124 10 124 6 0 10 -50 10 -130 0 -153 -3 -150 119 -150 97 0 131
11 131 42 0 21 2 20 21 -9 36 -56 140 -56 147 0 4 35 -33 119 -69 153 -16 15
-29 33 -29 39 0 23 -71 93 -109 109 -65 25 -70 18 -73 -97 -2 -69 -7 -102 -15
-105 -10 -3 -13 25 -13 117 l0 120 -33 25 c-67 51 -197 76 -211 40 -3 -9 -6
-81 -6 -160 0 -89 -4 -144 -10 -144 -6 0 -10 60 -10 160 0 155 -1 160 -23 174
-30 20 -123 28 -167 15z"
            />
            <path
                d="M2152 2098 c-7 -7 -12 -24 -12 -40 0 -33 24 -48 74 -48 l36 0 0 -148
0 -147 36 -54 c29 -43 35 -59 29 -85 -9 -45 -25 -158 -26 -181 0 -11 3 -35 7
-53 4 -21 2 -43 -6 -58 -10 -18 -11 -30 -2 -49 23 -49 13 -148 -21 -222 -14
-32 -4 -80 36 -164 l35 -74 1 583 1 582 25 0 25 0 2 -592 c3 -555 4 -593 21
-596 16 -3 17 30 17 592 l0 596 25 0 25 0 0 -567 1 -568 24 40 c14 22 25 50
25 63 0 13 7 36 15 53 15 28 14 35 -11 105 -27 77 -28 112 -3 171 10 26 10 38
-1 72 -10 32 -11 43 0 56 8 9 10 28 6 52 -4 21 -11 65 -15 98 -5 33 -12 70
-16 83 -6 18 1 31 34 67 l41 45 0 150 0 150 36 0 c50 0 74 15 74 48 0 16 -5
33 -12 40 -17 17 -509 17 -526 0z"
            />
            <path
                d="M1217 732 c-56 -14 -58 -15 -55 -46 2 -23 8 -32 26 -34 22 -3 22 -3
22 -208 l0 -204 44 0 c27 0 46 5 49 13 3 9 13 8 41 -5 70 -34 139 -14 190 55
31 42 35 160 8 214 -40 78 -101 96 -194 58 -16 -6 -18 1 -18 84 l0 91 -27 -1
c-16 -1 -54 -8 -86 -17z m208 -242 c22 -24 25 -108 5 -145 -15 -29 -55 -43
-79 -28 -25 16 -28 163 -5 180 26 19 58 16 79 -7z"
            />
            <path
                d="M1680 730 c-28 -28 -25 -64 6 -89 15 -12 33 -21 41 -21 21 0 63 46
63 70 0 28 -35 60 -65 60 -14 0 -34 -9 -45 -20z"
            />
            <path
                d="M90 696 c0 -26 5 -36 20 -41 13 -4 25 -23 35 -58 8 -29 35 -119 59
-202 l43 -150 56 -3 56 -3 27 90 c15 50 30 88 34 86 4 -2 17 -43 30 -90 l23
-86 55 3 55 3 54 190 c42 145 60 195 79 212 15 14 24 33 24 53 l0 30 -90 0
-90 0 0 -35 c0 -28 4 -35 19 -35 11 0 22 -4 25 -8 3 -5 -10 -65 -28 -133 -19
-68 -36 -117 -39 -109 -17 43 -57 225 -53 236 3 8 15 14 26 14 18 0 21 5 18
33 l-3 32 -100 0 -100 0 -3 -28 c-2 -19 3 -33 18 -45 11 -9 27 -37 34 -62 12
-42 11 -51 -14 -130 -16 -50 -30 -79 -33 -70 -18 44 -67 252 -62 260 3 6 13
10 21 10 9 0 14 11 14 35 l0 35 -105 0 -105 0 0 -34z"
            />
            <path
                d="M3470 695 c0 -28 4 -35 19 -35 37 0 42 -21 39 -186 l-3 -159 -27 -3
c-25 -3 -28 -8 -28 -38 l0 -34 120 0 120 0 0 34 c0 30 -3 35 -27 38 -25 3 -28
8 -31 44 -3 37 1 44 33 68 20 16 39 26 41 23 3 -3 26 -34 50 -69 l45 -63 -26
-3 c-21 -3 -25 -8 -25 -38 l0 -34 126 0 125 0 -3 33 c-2 24 -8 33 -25 35 -14
2 -50 40 -104 113 l-81 109 47 43 c67 59 107 87 122 87 8 0 13 13 13 35 l0 35
-110 0 -110 0 0 -35 c0 -28 4 -35 20 -35 11 0 20 -4 20 -8 0 -5 -35 -42 -77
-83 l-78 -74 -3 69 c-4 84 0 96 33 96 22 0 25 4 25 35 l0 35 -120 0 -120 0 0
-35z"
            />
            <path
                d="M907 586 c-84 -23 -127 -82 -127 -176 0 -79 37 -133 112 -165 70 -29
151 -12 221 47 l28 23 -25 24 -25 23 -30 -21 c-17 -12 -46 -25 -65 -28 -30 -5
-40 -1 -66 25 -16 17 -30 40 -30 51 0 20 5 21 116 21 l117 0 -6 46 c-11 104
-107 160 -220 130z m91 -72 c40 -28 28 -44 -33 -44 -41 0 -55 4 -55 14 0 16
37 46 55 46 6 0 21 -7 33 -16z"
            />
            <path
                d="M1720 584 c-19 -7 -50 -13 -67 -13 -31 -1 -33 -3 -33 -36 0 -31 3
-35 25 -35 25 0 25 0 25 -95 0 -95 0 -95 -25 -95 -22 0 -25 -4 -25 -35 l0 -35
105 0 105 0 0 35 c0 31 -3 35 -25 35 l-25 0 0 145 c0 158 2 153 -60 129z"
            />
            <path
                d="M1960 584 c-19 -7 -45 -13 -57 -13 -19 -1 -23 -7 -23 -36 0 -31 3
-35 25 -35 25 0 25 0 25 -95 0 -95 0 -95 -25 -95 -22 0 -25 -4 -25 -35 l0 -35
100 0 100 0 0 34 c0 25 -5 35 -20 39 -18 5 -20 14 -20 86 0 80 0 81 31 96 17
9 40 15 52 13 21 -3 22 -9 25 -100 3 -93 2 -98 -18 -98 -16 0 -20 -6 -20 -35
l0 -35 106 0 105 0 -3 32 c-2 25 -9 34 -28 38 -25 6 -25 8 -30 124 -5 110 -6
118 -30 136 -45 34 -119 33 -175 -4 -24 -16 -25 -16 -25 9 0 28 -14 30 -70 9z"
            />
            <path
                d="M2477 589 c-80 -19 -122 -70 -92 -115 24 -36 61 -32 86 11 11 19 29
37 39 40 30 10 60 -12 60 -44 0 -26 -3 -28 -67 -40 -111 -20 -143 -46 -143
-113 0 -85 95 -121 191 -72 23 11 31 10 68 -8 30 -15 51 -18 76 -14 32 6 35
10 35 41 0 27 -4 34 -22 37 -22 3 -23 9 -28 110 -7 127 -21 150 -102 167 -55
12 -49 12 -101 0z m93 -229 c0 -32 -22 -50 -63 -50 -46 0 -45 52 1 69 49 18
62 14 62 -19z"
            />
            <path
                d="M2860 584 c-19 -7 -45 -13 -57 -13 -19 -1 -23 -7 -23 -36 0 -31 3
-35 25 -35 l26 0 -3 -92 c-3 -88 -4 -93 -25 -96 -19 -3 -23 -10 -23 -38 l0
-34 105 0 105 0 0 35 c0 31 -3 35 -25 35 -25 0 -25 1 -25 85 0 92 -2 89 63 85
21 -1 44 4 52 10 20 16 19 74 -1 90 -24 21 -71 16 -98 -9 l-24 -23 -4 23 c-4
28 -21 31 -68 13z"
            />
            <path
                d="M3187 586 c-50 -18 -64 -31 -77 -70 -21 -64 17 -107 125 -142 74 -24
99 -42 84 -60 -24 -30 -80 -14 -135 38 -28 26 -34 28 -57 17 -32 -14 -32 -17
-10 -80 l18 -49 45 1 c25 0 60 -2 77 -6 108 -23 203 68 160 152 -16 30 -42 44
-149 84 -57 20 -75 43 -43 55 25 10 66 -5 95 -34 25 -26 29 -27 59 -15 17 7
31 14 31 16 0 1 -6 22 -14 47 -13 44 -14 45 -57 46 -24 0 -60 3 -79 7 -22 4
-49 2 -73 -7z"
            />
            <path
                d="M4184 590 c-54 -12 -101 -49 -123 -94 -92 -188 144 -352 305 -212
l39 34 -24 26 c-24 26 -25 26 -45 7 -30 -26 -61 -41 -89 -41 -24 0 -77 40 -77
59 0 5 -3 16 -6 25 -5 14 9 16 115 16 135 0 134 -1 110 71 -17 51 -65 96 -115
108 -47 12 -46 12 -90 1z m84 -87 l27 -28 -59 -3 c-32 -2 -60 -1 -62 1 -9 9
10 39 30 47 32 13 35 12 64 -17z"
            />
            <path
                d="M4437 583 c-4 -3 -7 -19 -7 -35 0 -22 5 -28 21 -28 16 0 33 -26 85
-136 66 -138 70 -158 41 -198 -14 -19 -16 -19 -51 -2 -54 25 -83 5 -77 -54 4
-28 42 -50 86 -50 71 0 93 27 199 246 79 162 98 194 116 194 18 0 21 5 18 33
l-3 32 -92 3 -93 3 0 -35 c0 -28 4 -35 23 -38 27 -4 26 -17 -10 -95 l-26 -58
-23 53 c-37 83 -40 102 -15 102 17 0 21 6 21 35 l0 35 -103 0 c-57 0 -107 -3
-110 -7z"
            />
        </g>
    </svg>
);

export default SvgWD;
