import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import "./qoute-of-the-day.styles.scss";

const QuoteOfTheDayComponent = ({ message }) => {
    const verse =
        typeof message.message !== "undefined" ? message.message[0].verse : "";
    const verseChapter =
        typeof message.message !== "undefined"
            ? message.message[0].verseChapter
            : "";

    return (
        <Container fluid className="quotes-container">
            <Row className="quotes-row">
                <Col className="quotes-col">
                    <h5 align="center">** Message Of The Day **</h5>
                    <p align="center">{verse}</p>
                    <p style={{ color: "#F28C28" }} align="right">
                        ({verseChapter})
                    </p>
                </Col>
            </Row>
        </Container>
    );
};

export default QuoteOfTheDayComponent;
