import React, { useEffect } from "react";
import { connect } from "react-redux";

import { createStructuredSelector } from "reselect";

// import { fetchCollectionsStart, fetchItemDescription } from './../../redux/shop/shop.actions';
import {getProductByNameStart, getProductByIdStart} from './../../redux/product/product.action';
// import { selectCollectionForProductDescription, selectCollections } from './../../redux/shop/shop.selector';
import { selectProductByName } from './../../redux/product/product.selector';
import { getAllOfferStart } from "../../redux/offer/offer.action";

import ProductDescriptionContainer  from '../../components/product-description/product-description.container';
import OfferRibbonComponent from "../../components/offer-ribbon/offer-ribbon.component";

import { selectResponseData } from "../../redux/offer/offer.selector";

import './product-description-page.styles.scss';

const ProductDescriptionPage = ({getProductByNameStart, getAllOfferStart, offerLink, selectProductByName, getProductByIdStart}) => {
    const path = window.location.pathname.split('/');
    const category = path[2].replace(/_+/g, ' ').toLowerCase();
    const product = path[3].replace(/_+/g, ' ').toLowerCase();

    const queryId = window.location.search.split('=')[1];
    // console.log(queryId);
   
    // if(typeof(collectionItemDetails) !== "undefined"){
    //     localStorage.setItem('collectionItemDetails', JSON.stringify(collectionItemDetails[0]));
    // }
    // const itemDetails = JSON.parse((localStorage.getItem('collectionItemDetails') !== "undefined" ? localStorage.getItem('collectionItemDetails') : "[]") || "[]");

    // var objLength = 1; 
    // if(typeof collectionItemDetails !== "undefined" && collectionItemDetails !== null){
    //     objLength = Object.keys(collectionItemDetails).length;
    // }
    useEffect(() => {
        getProductByNameStart(product);
        getAllOfferStart();
    }, [getProductByNameStart, getAllOfferStart]);

    // useEffect(() => {
    //     getProductByIdStart({id: queryId});
    // }, [getProductByIdStart]);

    // useEffect(() => {
    //     fetchCollectionsStart();
    // }, [fetchCollectionsStart]);

    // useEffect(() => {
    //     fetchItemDescription({category, product})
    // }, [collections]);
    
    useEffect(() => {
        window.scrollTo(0,0);
    }, []);

    return ( 
        <div className="product-description-page">
        {
            // typeof(collectionItemDetails) !== "undefined" && objLength !== 0 ?
            //     <ProductDescriptionContainer id={collectionItemDetails[0].id} collectionItem = {collectionItemDetails[0]}/> : <ProductDescriptionContainer id={itemDetails.id} collectionItem = {itemDetails}/>
            <>
                {
                    offerLink !== null && (offerLink[0].status === "Active" && <OfferRibbonComponent flag = {true}/>)
                }
                <ProductDescriptionContainer/> 
            </>  
            
        }
        </div>
    )
};

const mapStateToProps = createStructuredSelector({
    // collectionItemDetails: selectCollectionForProductDescription,
    // collections: selectCollections
    selectProductByName: selectProductByName,
    offerLink: selectResponseData
});

const mapDispatchToProps = dispatch => ({
    // fetchCollectionsStart: () => dispatch(fetchCollectionsStart()),
    // fetchItemDescription: collectionState => dispatch(fetchItemDescription(collectionState))
    getProductByNameStart: (name) => dispatch(getProductByNameStart(name)),
    getAllOfferStart: () => dispatch(getAllOfferStart())
    // getProductByIdStart: (id) => dispatch(getProductByIdStart(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductDescriptionPage);