import * as React from 'react';
import { useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import './offer-ribbon.styles.scss';

import { selectResponseData } from '../../redux/offer/offer.selector';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const OfferRibbonComponent = ({flag, offerLink, getAllOfferStart}) => {

  const [open, setOpen] = React.useState(flag);
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
          {
            offerLink !== null && 
            <img src={offerLink[0].link} alt="Offer" width="500" height="300"/>
          }
      </Dialog>
    </div>
  );
}


const mapStateToProps = createStructuredSelector({
  offerLink: selectResponseData
});

export default connect(mapStateToProps)(OfferRibbonComponent);