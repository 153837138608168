import styled, { css } from "styled-components";

const buttonStyles = css`
    background-color: #f28c28;
    color: #fbf2df;
    border: none;

    &:hover {
        background-color: #ffc000;
        color: #eee;
        border: 1px solid #f28c28;
    }
`;

const invertedButtonStyles = css`
    background-color: white;
    color: black;
    border: 1px solid black;

    &:hover {
        background-color: black;
        color: white;
        border: none;
    }
`;

const googleSignInStyles = css`
    background-color: #4285f4;
    color: white;

    &:hover {
        background-color: #357ae8;
        border: none;
    }
`;

const submitStyles = css`
    background-color: #f28c28;
    color: white;

    &:hover {
        background-color: white;
        border: none;
        border: 1px solid black;
    }
`;
const cancelStyles = css`
    background-color: #f44336;
    color: white;

    &:hover {
        background-color: white;
        border: none;
        border: 1px solid black;
    }
`;
const getCancelStyle = (props) => {
    if (props.isNormalCancelButton) {
        return cancelStyles;
    }
};

const getSubmitStyle = (props) => {
    if (props.isNormalSubmitButton) {
        return submitStyles;
    }
};

const getButtonStyles = (props) => {
    if (props.isGoogleSignIn) {
        return googleSignInStyles;
    }

    return props.inverted ? invertedButtonStyles : buttonStyles;
};

export const CustomButtonContainer = styled.button`
    min-width: 165px;
    width: auto;
    height: 50px;
    letter-spacing: 0.5px;
    line-height: 50px;
    padding: 0 35px 0 35px;
    font-size: 15px;
    text-transform: uppercase;
    font-family: "Dosis";
    font-weight: bolder;
    cursor: pointer;
    display: flex;
    justify-content: center;

    ${getButtonStyles};
    ${getSubmitStyle};
    ${getCancelStyle}
`;
