import React, { useEffect } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import QuoteOfTheDayContainer from "../qoute-of-the-day/quote-of-the-day.container";
import CrouselImage from "../crousel-image/crousel-image.component";
import DirectoryMenu from "../directory-menu/directory-menu.component";
import BannerComponent from "../banner/banner.component";
// import MenuItem from '../menu-item/menu-item.component';
import { getAllMessageStart } from "./../../redux/message/message.action";
import { selectDirectorySections } from "../../redux/directory/directory.selector";
import { selectAllMessage } from "../../redux/message/message.selector";

import "./directory.styles.scss";

const Directory = ({ sections, getAllMessageStart, message }) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        getAllMessageStart();
    }, [getAllMessageStart]);

    return (
        <div className="menu-directory">
            <CrouselImage />
            <QuoteOfTheDayContainer message={message} />
            <DirectoryMenu />
            <BannerComponent />
        </div>
    );
};

const mapStateToProps = createStructuredSelector({
    sections: selectDirectorySections,
    message: selectAllMessage,
});

const mapDispatchToProps = (dispatch) => ({
    getAllMessageStart: () => dispatch(getAllMessageStart()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Directory);
