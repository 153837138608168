import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`

    * {
        box-sizing: border-box;
    }

    html {
    }

    body{
        // font-family: 'Open Sans Condensed';
        
        // font-family: 'Dosis', serif !important;
        
        // font-family: 'Metal Mania', serif;
        font-family: 'Dosis', serif;
        // font-family: 'Jua', sans-serif;
        font-size: 16px !important;
        // color: #000;
        // background-color: #fbf2df;
        // background-image: url('+require("../src/assets/web_background/5.jpg")+');
        
        // padding: 1.25rem 3.75rem;

        @media screen and (max-width: 998px){
            // padding: 0.625rem;
            html {
                font-size: 55%
            }
        }
        @media screen and (max-width: 768px){
            // padding: 0.3125rem;
            html {
                font-size: 45%
            }
        }
        @media screen and (max-width: 550px){
            // padding: 0.625rem;
            html {
                font-size: 35%
            }
        }
        @media screen and (max-width: 360px){
            // padding: 0.3125rem;
            html {
                font-size: 25%
            }
        }
    }

    .font-metal-mania {
        font-family: 'Dosis', serif;
        // color: rgb(242, 140, 40);
    }

    .font-jua {
        font-family: 'Dosis', sans-serif;
        font-size: '18px';
        // color: rgb(242, 140, 40);
    }

    .font-emilys-candy {
        font-family: 'Dosis', serif;
        // color: rgb(242, 140, 40);
    }

    a {
        text-decoration: none;
        color: black;
    }

    .page-border {
        padding: 1.25rem 3.75rem;
    }
    .home-page-border {
        padding-left: 1.75rem;
    }
    .page-border-thin {
        padding: 1.25rem 1.75rem;
    }

    .icon-color {
        svg{
            color: rgb(242, 140, 40);
        }
    }
    .snack-alert {
        div{
            background-color: #000;
        }
    }
    .inherited-color {
        background-color: #000;
    }
    .App {
        height: 100vh;
        display:flex; 
        flex-direction:column; 
    }

    .ul-display li{
        display: list-item;
        list-style-type: unset;
        list-style-position: outside;
        margin-left: 2rem;
    }
    .m-l-0 {
        margin-left: 0;
    }
    .m-r-0 {
        margin-right: 0;
    }
    .m-t-30{
        margin-top: 30px;
    }

    .m-l-r-auto {
        margin-left: auto;
        margin-right: auto;
    }
    .animate-charcter {
        text-transform: uppercase;
        background-image: linear-gradient(
            -225deg,
            #FF5733 0%,
            #FF7F50 29%,
            #F28C28 67%,
            #fff800 100%
        );
        background-size: auto auto;
        background-clip: border-box;
        background-size: 200% auto;
        color: #fff;
        background-clip: text;
        // text-fill-color: transparent;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        animation: textclip 2s linear infinite;
        display: inline-block;
        font-size: auto;
    }

    .clickable-button {
        display: flex;
        margin: 0;
        flex-direction: column;
        padding: 0;
        padding-bottom: 10px;
    
        div {
            width: fit-content;
            margin-left: auto;
            margin-right: 20px;
            color: #fff;
            background-color: rgb(242, 140, 40);
            cursor: pointer;
        }
        div:hover {
            background-color: rgb(255, 192, 0);
        }
    }

    .divider-chip > span {
        color: #000;
    }

    // MUI
    .MuiTooltip-tooltip {
        // font-family: 'Jua', sans-serif;
        background-color: #000;
    }
    .MuiChip-label {
        color: white;
        font-family: "Dosis";
    }
    .MuiTypography-subtitle1 {
        // font-family: 'Jua', sans-serif;
        color: #000;
    }
    .MuiTypography-body2 {
        // font-family: 'Jua', sans-serif;
        color: rgb(242, 140, 40);
    }


    @media (min-width: 320px) and (max-width: 768px) {
        html {
            font-size: small;
        }
    }
`;
